<template>
    <div>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <b-card-text v-if="namePlan">
                <h1 class="p-2">Plan: {{ namePlan.plan.name }}</h1>
            </b-card-text>
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50" />
                    </b-col>
                    <b-col md="4">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="form.name" class="d-inline-block mr-1" placeholder="Nombre" />
                        </div>
                    </b-col>
                    <b-col md="4">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="form.description" class="d-inline-block mr-1"
                                placeholder="Descripción" />
                        </div>
                    </b-col>
                    <b-col md="1" class="d-flex">
                        <b-button variant="success" class="btn-icon rounded-circle" @click="store()"
                            style="width:40px; height: 40px;">
                            <feather-icon icon="PlusIcon" />
                        </b-button>
                    </b-col>
                    <b-col cols="12" md="12" class="mt-2">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-col md="4" class="offset-8">
                                <b-input-group size="sm">
                                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Buscar" />
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">
                                            Borrar
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table ref="refRoleListTable" class="position-relative" :items="dataTable" responsive :fields="tableColumns"
                primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
                :sort-desc.sync="tableSettings.sortDesc" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered">

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button variant="primary" class="btn-icon rounded-circle mr-1"
                        @click="openModalEditCoverage(data.item)" v-b-tooltip.hover.v-primary title="Editar">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button variant="danger" class="btn-icon rounded-circle" @click="deleteCobertura(data.item.id)"
                        v-b-tooltip.hover.v-primary title="Eliminar">
                        <feather-icon icon="Trash2Icon" />
                    </b-button>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of
                        }}
                            registros</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination v-model="tableSettings.page" :total-rows="totalRows" :per-page="tableSettings.perPage"
                            first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
                            next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>

        </b-card>
        <b-modal ref="my-modal" hide-footer title="Editar">
            <div class="d-block text-center">
                <b-row>
                    <b-col md="12" class="mb-1">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="modalForm.name" class="d-inline-block mr-1" placeholder="Nombre" />
                            <b-form-input v-model="modalForm.id" class="d-none" placeholder="Nombre" />
                        </div>
                    </b-col>
                    <b-col md="12" class="mb-1">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="modalForm.description" class="d-inline-block mr-1"
                                placeholder="Descripción" />
                        </div>
                    </b-col>
                    <b-col md="12">
                        <b-button variant="success" class="btn-icon" @click="editCoverage()">
                            Editar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>
  
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormGroup, BModal, BCardText, BInputGroup,
    BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
    name: 'cotizationHistory',
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        VBTooltip,
        BFormGroup,
        BModal,
        BCardText,
        BInputGroup,
        BInputGroupAppend
    },
    data() {
        return {
            filter: null,
            filterOn: [],
            namePlan: null,
            modalForm: {
                name: null,
                id: null,
                description: null
            },
            id: this.$route.params && this.$route.params.id,
            formDisabled: false,
            refRoleListTable: null,
            perPageOptions: [25, 50, 100],
            searchQuery: '',
            tableColumns: [
                { key: 'name', label: 'Nombre', sortable: true },
                { key: 'description', label: 'Descripción', sortable: true },
                { key: 'actions', label: 'acciones' },
            ],
            sortBy: 'id',
            isSortDirDesc: false,
            totalRows: 0,
            dataMeta: {
                from: 0,
                to: 0,
                of: 0,
            },
            dataTable: [],
            showLoadingTable: false,
            tableSettings: {
                searchQuery: '',
                input: '',
                perPage: 25,
                page: 1,
                sortBy: 'id',
                sortDesc: false,
            },
            form: {
                name: '',
                description: '',
                id: this.$route.params && this.$route.params.id,
            }
        }
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    watch: {
        "tableSettings.sortBy": {
            handler(val) {
                this.fetchList()
            },
        },
        "tableSettings.sortDesc": {
            handler(val) {
                this.fetchList()
            },
        },
        "tableSettings.perPage": {
            handler(val) {
                this.fetchList()
            },
        },
        "tableSettings.searchQuery": {
            handler(val) {
                this.fetchList()
            },
        },
        "tableSettings.page": {
            handler(val) {
                this.fetchList()
            },
        },
    },
    created() {
        this.fetchList()
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        editCoverage() {
            this.$http.post('/coverage/update', this.modalForm)
                .then(response => {
                    if (response.data.code == 200) {
                        this.$swal({
                            title: response.data.message,
                            icon: 'success',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        });
                        this.fetchList();
                        this.modalForm = {
                            name: '',
                            description: ''
                        }
                        this.$refs['my-modal'].hide()
                    }
                    if (response.data.code == 500) {
                        this.$swal({
                            title: response.data.message,
                            icon: 'warning',
                            customClass: {
                                confirmButton: 'btn btn-warning',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                });
        },
        openModalEditCoverage(data) {
            this.$refs['my-modal'].show()
            this.modalForm.id = data.id
            this.modalForm.name = data.name
            this.modalForm.description = data.description
        },
        store() {
            this.$http.post('/coverage/store', this.form)
                .then(response => {
                    if (response.data.code == 200) {
                        this.$swal({
                            title: response.data.message,
                            icon: 'success',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        });
                        this.fetchList();
                        this.form = {
                            name: '',
                            description: '',
                            id: this.$route.params && this.$route.params.id,
                        }
                    }
                    if (response.data.code == 500) {
                        this.$swal({
                            title: response.data.message,
                            icon: 'warning',
                            customClass: {
                                confirmButton: 'btn btn-warning',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                });
        },
        fetchList() {
            this.showLoadingTable = true
            this.$http.get('/coverage/list/' + this.id, { params: this.tableSettings }).then((response) => {
                this.dataTable = response.data.coverages
                this.totalRows = response.data.total
                this.dataMetaCounter()
                this.showLoadingTable = false
                this.namePlan = response.data.namePlan
            })
        },
        dataMetaCounter() {
            const localItemsCount = this.dataTable.length
            this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
            this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
            this.dataMeta.of = this.totalRows
        },
        deleteCobertura(id) {
            this.$swal({
                title: `Esta séguro de eliminar la cobertura ${id}?`,
                text: "Este proceso no se puede revertir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Eliminar!',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.post('/coverage/delete/' + id).then(() => {
                        this.fetchList()
                        this.$swal({
                            icon: 'success',
                            title: 'Cobertura eliminado!',
                            text: 'La Cobertura ha sido eliminada.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    })
                } else if (result.dismiss === 'cancel') {
                    this.$swal({
                        title: 'Cancelado',
                        text: 'Eliminación cancelada',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        }
    },
}
</script>
  
<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
  